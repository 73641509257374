import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
  ) {
    this.loginForm = new FormGroup({
      "email": new FormControl('', Validators.compose([Validators.required])),
      "password": new FormControl('', Validators.compose([Validators.required]))
    })
  }

  ngOnInit(): void {
  }

  get a() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return
    }
    this.commonService.presentSpinner();
    this.authService.login(this.loginForm.value).then(
      (res) => {
        if (res && res.data) {
          this.commonService.showSuccessToastMsg('', 'Logged in successfully!');
          this.storage.set(storage_Keys.adminData, res.data);
          let authtoken = {};
          authtoken[storage_Keys.authtoken] = res.data.authToken
          this.storage.set(storage_Keys.authtoken, authtoken);
          this.storage.setData(storage_Keys.userType,res.data.type);
          if (res.data?.role == "superadmin") {
            this.router.navigate(['admin/company']);
          } else {
            this.router.navigate(['admin/dashboard']);
          }
        }
      }
    ).catch(
      (error) => {

      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }

}
