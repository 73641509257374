import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private http: HttpWrapperService
  ) { }

  presentSpinner() {
    this.spinner.show();
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  showSuccessToastMsg(title?: string, message?: string) {
    this.toast.success(message, title, {
      timeOut: 2000
    });
  }

  showErrorToastMsg(title?: string, message?: string) {
    this.toast.error(message, title, {
      timeOut: 3000
    });
  }

  public matchPassword(AC: AbstractControl) {
    let password = AC.get('password').value;
    if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
      let verifyPassword = AC.get('confirmPassword').value;
      if (password != verifyPassword) {
        AC.get('confirmPassword').setErrors({ matchPassword: true });
      } else {
        return null;
      }
    }
  }

  public matchChnagePassword(AC: AbstractControl) {
    let password = AC.get('newPassword').value;
    if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
      let verifyPassword = AC.get('confirmPassword').value;
      if (password != verifyPassword) {
        AC.get('confirmPassword').setErrors({ matchPassword: true });
      } else {
        return null;
      }
    }
  }

  calculateEntries(list: Array<any>, current_page: number, limit: number, totalResult: number): Entries {
    if (list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      list = pages[current_page];
    }
    return {
      from: limit * (current_page - 1) + 0 + 1,
      to: limit * (current_page - 1) + (list.length - 1) + 1,
      total: totalResult,
    };
  }

  handleError(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      this.showErrorToastMsg(error.error.message);
    } else {
      this.showErrorToastMsg('Something went wrong. Please try again or later.');
    }
  }

  commonData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.common.commonData).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
          } else { }
          reject(error);
        }
      );
    });
  }

  branchList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.common.branchList).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
          } else { }
          reject(error);
        }
      );
    });
  }

  departmentList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.common.departmentList).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
          } else { }
          reject(error);
        }
      );
    });
  }

  employeesList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.common.employeesList).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
          } else { }
          reject(error);
        }
      );
    });
  }

  designationList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.common.designationList).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
          } else { }
          reject(error);
        }
      );
    });
  }

  companyList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.common.companyList).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
          } else { }
          reject(error);
        }
      );
    });
  }


}

export interface Entries {
  from: any;
  to: any;
  total: any;
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
      return { mustMatch: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  }
}
