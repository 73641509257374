import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  showMsg: any;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
  ) {
    this.forgotForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required]))
    })
  }

  ngOnInit(): void {
  }

  get a() {
    return this.forgotForm.controls;
  }

  forgotPassword() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return
    }
    this.commonService.presentSpinner();
    this.authService.forgotPassword(this.forgotForm.value).then(
      (res: any) => {
        if (res) {
          this.commonService.showSuccessToastMsg(res.message)
          this.showMsg = res.message
        }
      }
    ).catch(
      (error) => {

      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.submitted = false;
        this.forgotForm.reset();
      }
    )
  }

}
