<div class="page-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="logo">
          <img class="img-fluid" style="max-width: 480px; margin: 0 auto; display: block;"
            src="../../../assets/images/logo.svg" alt="logo">
        </div>
        <div class="top-title text-center">
          <h1>WELCOME BACK</h1>
          <!-- <p>Login into the account to access the features.</p> -->
        </div>
        <div class="form-card">
          <h2 class="form-card-title">RESET Password</h2>
          <form [formGroup]="passwordForm" class="custom-form login-form">
            <div class="form-group">
              <label>Password :</label>
              <input type="password" formControlName="password" class="form-control">
              <div class="invalid-feedback" *ngIf="submitted && a.password.errors">
                <span *ngIf="a.password.errors.required">Password is required.</span>
              </div>
            </div>
            <div class="form-group">
              <label>Confirm Password :</label>
              <input type="password" formControlName="confirmPassword" class="form-control">
              <div class="invalid-feedback" *ngIf="submitted && a.confirmPassword.errors">
                <span *ngIf="a.confirmPassword.errors.required">Confirm Password is required.</span>
              </div>
            </div>
            <div class="form-group text-center mb-0">
              <button type="button" (click)="login()" class="btn btn-primary w-md">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
