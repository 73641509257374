<div class="page-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="logo">
          <img class="img-fluid" style="max-width: 480px; margin: 0 auto; display: block;"
            src="../../../assets/images/logo.svg" alt="logo">
        </div>
        <div class="top-title text-center">
          <h1>WELCOME BACK</h1>
          <p>Login into the account to access the features.</p>
        </div>
        <div class="form-card">
          <h2 class="form-card-title">STAFF LOGIN</h2>
          <form [formGroup]="loginForm" class="custom-form login-form">
            <div class="form-group">
              <label>Email Address or Username :</label>
              <input type="email" formControlName="email" class="form-control">
              <div class="invalid-feedback" *ngIf="submitted && a.email.errors">
                <span *ngIf="a.email.errors.required">Email id is required.</span>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" formControlName="password" class="form-control">
              <div class="invalid-feedback" *ngIf="submitted && a.password.errors">
                <span *ngIf="a.password.errors.required">Password is required.</span>
              </div>
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="signedIn">
                <label class="form-check-label" for="signedIn">Keep Me Signed In</label>
              </div>
              <button type="button" [routerLink]="['/forgot-password']" class="btn btn-link">Forgot Password?</button>
            </div>
            <div class="form-group text-center mb-0">
              <button type="button" (click)="login()" class="btn btn-primary w-md">LOGIN</button>
              <!-- <button type="button" class="btn btn-dark">CANCEL</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
