<div class="page-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="logo">
          <img class="img-fluid" style="max-width: 480px; margin: 0 auto; display: block;"
            src="../../../assets/images/logo.svg" alt="logo">
        </div>
        <div class="top-title text-center">
          <h1>Forgot Password ?</h1>
        </div>
        <div class="form-card">
          <div class="text-center mb-4">
            <h5 class="form-card-title mb-2">Enter the email address associated with your account</h5>
            <p class="text-muted">We will email you a link to reset your password</p>
            <p class="text-success" *ngIf="showMsg">{{showMsg}}</p>
          </div>
          <form [formGroup]="forgotForm" class="custom-form login-form">
            <div class="form-group">
              <label>Email Address </label>
              <input type="email" formControlName="email" placeholder="Enter Email Address" class="form-control">
              <div class="invalid-feedback" *ngIf="submitted && a.email.errors">
                <span *ngIf="a.email.errors.required">Email id is required.</span>
              </div>
            </div>
            <div class="form-group d-flex align-items-center justify-content-end">
              <button type="button" [routerLink]="['/login']" class="btn p-0 btn-link">Back to login</button>
            </div>
            <div class="form-group text-center mb-0">
              <button type="button" (click)="forgotPassword()" class="btn btn-primary  w-md">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
