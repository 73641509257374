import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { storage_Keys } from './services/storage/storage.keys';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

class RedirectUrl {
  /**
   *
   * @param path parameter accepts only```''``` (empty path) or ```'**'``` (wild route)
   * @returns  an angular ```Route```
   */
  static getRedirectUrl(path: '' | '**'): Route {
    const savedData = JSON.parse(JSON.parse(localStorage.getItem(storage_Keys.authtoken)));
    if (savedData && savedData.authtoken) {
      return { path: path, redirectTo: '/admin/dashboard', pathMatch: 'full' };
    } else {
      return { path: path, component: LoginComponent };
    }
  }
}

const routes: Routes = [
  RedirectUrl.getRedirectUrl(''),
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {path: 'form-buidler', loadChildren: () =>import('./components/form-builder/form-builder.module').then(m => m.FormBuilderModule),canActivate: [AuthGuard]},
  RedirectUrl.getRedirectUrl('**'),
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top',
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
