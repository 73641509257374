import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  submitted = false;
  token: string = ``;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.passwordForm = new FormGroup({
      "password": new FormControl('', Validators.compose([Validators.required])),
      "confirmPassword": new FormControl('', Validators.compose([Validators.required])),
      "token": new FormControl()
    })
  }

  ngOnInit() {
    // Accessing query parameters
  this.route.queryParams.subscribe(params => {
    this.token = params['token'];
    this.passwordForm.get('token').setValue(this.token);
  });
  }

  get a() {
    return this.passwordForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return
    }
    this.commonService.presentSpinner();
    this.authService.resetPassword(this.passwordForm.value).then(
      (res) => {
        if (res) {
          this.commonService.showSuccessToastMsg('', res['message']);
          this.router.navigate(['/login']);
        }
      }
    ).catch(
      (error) => {

      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.submitted = false;
        this.passwordForm.reset();
      }
    )
  }
}


