<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- <footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <script>
          document.write(new Date().getFullYear())
        </script>2021 © ARC application, version 1.1
      </div>
    </div>
  </div>
</footer> -->
