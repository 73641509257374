import { Injectable } from '@angular/core';
import { storage_Keys } from './storage.keys';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private savedData: any = {};

  constructor() { }

  public getSavedData() {
    this.savedData = {};
    Object.keys(storage_Keys).forEach(
      (key) => {
        if (key) {
          let data = JSON.parse(JSON.parse(this.get(storage_Keys[key as keyof typeof storage_Keys])!));
          if (data) {
            this.savedData[storage_Keys[key as keyof typeof storage_Keys]] = data;
          }
        }
      }
    );

    return this.savedData;
  }

  public set(key: string, data: any) {
    if (!data) {
      return;
    }
    return localStorage.setItem(key, JSON.stringify(JSON.stringify(data)));
  }
  public setData(key: string, data: any) {
     //return localStorage.setItem(key, data);
  }
  public getData(key: string) {
   // return localStorage.getItem(key);
  }

  private get(key: string) {
    return localStorage.getItem(key);
  }

  public removeItem(key: string) {
    delete this.savedData[key];
    return localStorage.removeItem(key);
  }

  private clearStorage() {
    localStorage.clear();
  }

  public clearStorageForLogout() {
    return new Promise((resolve, reject) => {
      let count = 0
      Object.keys(storage_Keys).forEach(
        (key) => {
          // if (key && storageKeys[key] !== storageKeys.lastSelectedLanguage && storageKeys[key] !== storageKeys.rememberMe) {
          this.removeItem(storage_Keys[key as keyof typeof storage_Keys]);
          count++;
          // }
        }
      );
      if (count === Object.keys(storage_Keys).length) {
        resolve(true);
      }
    })
    this.savedData = {};
  }
}
